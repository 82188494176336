export default function RelativeTime(timestamp: number) {
  const elapsedSeconds = Math.round((+new Date() - timestamp) / 1000);
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedSeconds / 3600);
  const elapsedDays = Math.floor(elapsedSeconds / 86400);

  if (elapsedSeconds < 60) {
    return `hace ${elapsedSeconds} ${elapsedSeconds == 1 ? "segundo" : "segundos"}`;
  } else if (elapsedMinutes < 60) {
    return `hace ${elapsedMinutes} ${elapsedMinutes == 1 ? "minuto" : "minutos"}`;
  } else if (elapsedHours < 24) {
    return `hace ${elapsedHours} ${elapsedHours == 1 ? "hora" : "horas"}`;
  } else {
    return `hace ${elapsedDays} ${elapsedDays == 1 ? "dia" : "dias"}`;
  }
}
