import * as React from "react";
import type { PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import isBrowser from "../../utils/isBrowser";
import { ProofPopup } from "components/ProofPopup";
import Metadata, { IMetadataProps } from "components/Metadata";
import { Wrapper, Modal, Urgency } from "components/MasterclassFunnel";
import { PlayButton, PlayButtonUrgent, VideoPlayButton } from "components/MasterclassFunnel";
import { Heading1, Heading2, Heading3, Paragraph } from "components/MasterclassFunnel";
import { LogoElConfidencial, LogoCincoDias, LogoOkDiario } from "components/MasterclassFunnel";
import { LogoPronto, LogoBazaar, LogoHola, LogoForbes } from "components/MasterclassFunnel";
import { LogoWomensHealth, LogoFueraDeSerie } from "components/MasterclassFunnel";
import { LogoTheLuxonomist, LogoDiezMinutos } from "components/MasterclassFunnel";

// import masterclassPreviewGIF from "static/gifs/masterclass-preview.gif";
import masterclassPreviewWEBM from "static/videos/masterclass-preview-30.webm";
import masterclassPreviewMP4 from "static/videos/masterclass-preview-30.mp4";
import masterclassPreviewGIF from "static/gifs/masterclass-preview.gif";
import PageEventsAnalytics from "components/PageEventsAnalytics";

export default ({ location }: PageProps) => {
  const meta: IMetadataProps = {
    pageTitle: "Los Secretos de las Celebrities",
    domain: "https://v2.drplanascosmetica.com/",
    url: "https://v2.drplanascosmetica.com/secretos",
    title: "Los 3 Secretos Anti-Envejecimiento  de las Celebrities",
    image: "https://i.pinimg.com/564x/f2/93/30/f29330332a9a367d6188457aa8e948a0.jpg",
    desc: "¡Al Descubierto! El Dr Planas desvela los secretos contra el envejecimiento que usan las top celebrities",
  };
  const [show, setShow] = React.useState(false);

  return (
    <div className={`${show && "overflow-hidden"}`}>
      <Metadata {...meta} />

      {/* <PageEventsAnalytics
        state={location.state as any}
        log={process.env.GATSBY_ENV == "production"}
      /> */}

      <ProofPopup id="secretos_null_ProofPopup" />
      {show && <Modal onClose={() => setShow(false)} />}

      <HeroSection setShow={setShow} />
      <LogoCloudSection />
      <QuoteSection />
      <IncentivesSection setShow={setShow} />
      <AboutSection setShow={setShow} />
      <ReviewsSection />
      <CTASection setShow={setShow} />
      <Footer />
    </div>
  );
};

// -------------------------------------------------------------------------------------------------
// HERO START
// -------------------------------------------------------------------------------------------------
interface IStateProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeroSection = ({ setShow }: IStateProps) => {
  React.useEffect(() => {
    // document.getElementById("hero-video").play();
  }, []);
  return (
    <section id="secretos_HERO" className="relative">
      <Wrapper className="">
        <div id="HEADER" className="text-gray-300 flex justify-center items-cente -mt-10 mb-10">
          <h1 className="uppercase text-sm pt-3 font-['Copperplate_Gothic']">Dr. Jorge Planas</h1>
          <DrPlanasLogo className="h-10 w-10" />
        </div>
        <div id="HERO">
          <StaticImage
            alt="Dr Planas en despacho"
            src="../../static/masterclass-hero-bg.jpg"
            className="top-0 left-0 h-full w-full z-[-2] brightness-[0.2]"
            style={{ position: "absolute" }}
          />
          <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-[#1B4A93]/10" />
          <Heading1 className="text-gray-100">
            Los 3 Metodos Secretos de las Top Celebrities Para Combatir el Envejecimiento de Forma
            NATURAL y SIN Pasar por el Quirofano
          </Heading1>
          <PlayButton
            // Mobile play button
            children="Quiero Verlo"
            className="sm:hidden mt-10"
            onClick={() => setShow(true)}
          />
          <div className="flex flex-col sm:flex-row sm:flex-wrap gap-10 mt-12 items-center">
            <div className="relative flex-1 w-full shadow-[0_0_20px_rgba(29,74,255,0.7)] hover:shadow-none transition-all ">
              <div className="relative aspect-video group">
                {/* Large GIFs are inefficient. Using videos, we save on user's bandwidth */}
                {/* If UA string doesn't contain Chrome, render GIF because video won't work */}
                {/* isBrowser used to avoid SSR issues where window is not defined */}
                {isBrowser() && !(window.navigator || {}).userAgent.includes("Chrome") ? (
                  <img src={masterclassPreviewGIF} alt="Vista anticipada de la masterclass" />
                ) : (
                  <video id="hero-video" autoPlay loop muted>
                    <source src={masterclassPreviewWEBM} type="video/webm" />
                    <source src={masterclassPreviewMP4} type="video/mp4" />
                  </video>
                )}

                <button
                  id="button-wrapper"
                  onClick={() => setShow(true)}
                  className="w-full h-full absolute top-0 left-0 flex justify-center items-center"
                >
                  <VideoPlayButton id="secretos_HERO_VideoPlayButton" />
                </button>
              </div>
            </div>
            <div className="flex-1">
              <Paragraph className="text-gray-200 space-y-4 text-[22px] leading-[28px]">
                <p>
                  En esta masterclass gratuita, el prestigioso Dr. Jorge Planas, revela los secretos
                  mejor guardados que rejuvenecerán tu piel de 10 a 15 años.
                </p>
                <p>Estos son los métodos que usan las Top Celebrities.</p>
                <p>Métodos que puedes implementar tú desde casa, hoy mismo.</p>
                <p>Las plazas son limitadas, ¡reserva la tuya hoy!</p>
              </Paragraph>

              <PlayButtonUrgent
                id="secretos_HERO_PlayButtonUrgent"
                children="Quiero Verlo"
                onClick={() => setShow(true)}
                className="mt-10"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
// -------------------------------------------------------------------------------------------------
// HERO END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// LOGO CLOUD START
// -------------------------------------------------------------------------------------------------
const LogoCloudSection = () => (
  <section id="secretos_LOGOCLOUD" className="bg-white grayscale opacity-[0.6]">
    {/* <Wrapper className="pt-0 pb-0"> */}
    <div className="max-w-7xl mx-auto px-5 py-7 md:py-5">
      <div className="grid grid-cols-3 lg:grid-cols-6 gap-6 place-items-center">
        <LogoBazaar className="h-6" />
        <LogoForbes className="h-6" />
        <LogoDiezMinutos className="h-10" />
        <LogoHola className="h-6 md:h-7" />
        <LogoFueraDeSerie className="h-6 md:h-6" />
        <LogoElConfidencial className="h-4 md:h-6" />
      </div>
      <div className="mt-5 md:mt-3 grid grid-cols-6 md:grid-cols-5 gap-6 place-items-center">
        <LogoOkDiario className="h-7 md:h-8 aspect-[711/200] col-span-2 md:col-span-1" />
        <LogoTheLuxonomist className="h-3 md:h-3.5 aspect-[544/51] col-span-2 md:col-span-1" />
        <LogoPronto className="h-5 md:h-6 col-span-2 md:col-span-1" />
        <LogoWomensHealth className="h-6 col-span-3 md:col-span-1" />
        <LogoCincoDias className="h-5 col-span-3 md:col-span-1" />
      </div>
    </div>
    {/* </Wrapper> */}
  </section>
);
// -------------------------------------------------------------------------------------------------
// LOGO CLOUD END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// QUOTE START
// -------------------------------------------------------------------------------------------------
const QuoteSection = () => (
  <section id="secretos_QUOTE" className="relative overflow-hidden">
    <div className="absolute top-0 left-0 h-full w-full z-[-1] bg-[#1B4A93]/10 brightness-[1.4]" />
    <StaticImage
      alt="Dr Jorge Planas Operando"
      src="../../static/dr-planas-operando.png"
      className="left-0 h-full max-w-2xl object-cover object-center z-[-2] contrast-[1.2] brightness-[2] md:brightness-[1.3] opacity-[0.3]"
      style={{ position: "absolute", clipPath: "polygon(0 0, 90% 0, 40% 100%, 0 100%)" }}
    />
    <Wrapper className="flex flex-col justify-center items-center">
      <blockquote className="flex text-xl md:text-2xl leading-9 font-extrabold md:font-bold text-center">
        <QuoteIcon position="start" />
        Entre los 100 mejores médicos de España
        <QuoteIcon position="end" />
      </blockquote>
      <LogoForbes className="h-7 mt-4" />
    </Wrapper>
  </section>
);
// -------------------------------------------------------------------------------------------------
// QUOTE END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// INCENTIVES START
// -------------------------------------------------------------------------------------------------
const IncentivesSection = ({ setShow }: IStateProps) => {
  interface IIncentivesProps {
    name: string;
    title: string;
    Icon: React.ElementType;
  }
  const incentives: IIncentivesProps[] = [
    {
      name: "3 Secretos",
      title: "3 Secretos que rejuvenecen tu piel de 10-15 años",
      Icon: () => <HeartAnimated className="h-20 w-20 mx-auto" />,
    },
    {
      name: "Causas del Envejecimiento",
      title: "Las verdaderas causas del envejecimiento",
      Icon: () => <ClockAnimated className="h-20 w-20 mx-auto" />,
    },
    {
      name: "5 Ingredientes",
      title: "5 potentes ingredientes anti-aging desconocidos",
      Icon: () => <PlantAnimated className="h-20 w-20 mx-auto" />,
    },
  ];
  return (
    <section id="secretos_INCENTIVES" className="bg-gray-50">
      <Wrapper>
        <Heading3>En esta Masterclass gratuita, aprenderás:</Heading3>
        <div className="mt-12 max-w-2xl mx-auto px-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3">
          {incentives.map(({ Icon, name, title }) => (
            <div
              key={name}
              className="text-center sm:flex items-center sm:text-left lg:block lg:text-center"
            >
              <div className="sm:flex-shrink-0">
                <div className="flow-root">
                  <Icon />
                </div>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3 lg:mt-3 lg:ml-0 ">
                <h3 className="text-[18px] md:text-[20px] font-medium text-gray-900 max-w-xs mx-auto">
                  {title}
                </h3>
              </div>
            </div>
          ))}
        </div>
        <PlayButton
          // Mobile play button
          children="Quiero Verlo"
          className="sm:hidden mt-32"
          onClick={() => setShow(true)}
        />
      </Wrapper>
    </section>
  );
};
// -------------------------------------------------------------------------------------------------
// INCENTIVES END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// ABOUT START
// -------------------------------------------------------------------------------------------------

const AboutSection = ({ setShow }: IStateProps) => {
  return (
    <section id="secretos_ABOUT" className="relative ">
      <div className="md:hidden overflow-hidden">
        <StaticImage
          alt="El Dr Jorge Planas"
          src="../../static/dr-planas-portada.jpg"
          className="absolute w-full h-72 overflow-hidden z-[-3] opacity-[0.3]"
          imgClassName="object-top"
        />
      </div>
      <Wrapper className="md:flex gap-12">
        <div className="md:w-7/12">
          <Heading3>¿Quien es el Dr. Jorge Planas?</Heading3>
          <Heading2>
            Conocimiento puntero avalado por 30.000 pacientes y 30 años de experiencia
          </Heading2>

          <Paragraph className="text-[#1B4A93] brightness-50 text mt-10 md:mt-0">
            <p>
              Elegido por la revista Forbes entre los 100 mejores médicos de España, Dr. Jorge
              Planas cuenta con 30 años de experiencia en Cirugía Plástica, Reparadora y Estética.
            </p>
            <p className="my-2">
              Hoy lidera operaciones en la prestigiosa Clínica Planas en Barcelona y Madrid, donde
              ya ha tratado a más de 30.000 pacientes.
            </p>
            <p>
              En esta masterclass gratuita, compartirá contigo sus métodos infalibles anti-aging.
              ¡Nos vemos allí!
            </p>
          </Paragraph>
          <PlayButtonUrgent
            id="secretos_ABOUT_PlayButtonUrgent"
            children="Quiero Verlo"
            onClick={() => setShow(true)}
            className="mt-10"
          />
        </div>
        <StaticImage
          alt="El Dr Jorge Planas"
          src="../../static/dr-planas-portada.jpg"
          className="md:w-5/12 h-60 overflow-hidden hidden md:block"
          imgClassName="object-top"
        />
      </Wrapper>
    </section>
  );
};
// -------------------------------------------------------------------------------------------------
// ABOUT END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// REVIEWS START
// -------------------------------------------------------------------------------------------------
const ReviewsSection = () => {
  const quotes = [
    {
      name: "Miguel",
      text: "Jorge. He enviado tu video como a unas 70 personas y he tenido una lluvia de Whatsapp dándome las gracias. Es importante que personas como tú nos ayudes con consejos.",
    },
    {
      name: "Eva González",
      text: "Muchísimas gracias por haberme permitido asistir a este webinar . Sinceramente, la charla me pareció bastante clara, amena e interesante. Agradezco la difusión de la importancia de una rutina “simple”. A día de hoy con el boom de la rutina coreana en muchas pieles el exceso de productos puede producir efectos no deseados",
    },
    {
      name: "Carlos Vilà Sagnier",
      text: "Le estaré siempre agradecido al Dr. Planas. De estar a punto de perder mi expresión facial a que prácticamente no se note nada.",
    },
  ];
  const Quote = ({ name, text }: { name: string; text: string }) => (
    <div className="relative">
      <QuoteIcon
        position="start"
        className={
          `absolute left-0 top-0 stroke-black stroke-2 opacity-[0.3] ` +
          `h-[30px] w-[30px] md:h-[40px] md:w-[40px] mx-[-8px] my-[-18px] md:m-[-18px]`
        }
      />
      <p className=" md:text-lg md:leading-9 font-light">{text}</p>
      <div className="mt-2 md:mt-4 flex items-center">
        <p className="font-medium opacity-80">{name}</p>
        <div className="ml-3 flex">
          {[1, 2, 3, 4, 5].map((idx) => (
            <StarIcon key={idx} className="h-5 w-5 fill-yellow-300 stroke-yellow-500" />
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <section id="secretos_REVIEWS" className="bg-gray-50">
      <Wrapper className="gap-12">
        <Heading3>Reseñas</Heading3>
        <Heading2>Lo que dicen sobre el Doctor</Heading2>
        <div className="md:flex gap-12 items-center">
          <StaticImage
            alt="Dr Planas con Nuñes Alvarez"
            src="../../static/dr-planas-review.jpg"
            className="md:w-1/2 w-full h-48 md:h-60 overflow-hidden"
            imgClassName="object-top"
          />
          <div className="relative text-center mt-10 md:mt-0">
            <QuoteIcon
              position="start"
              className={
                `absolute left-0 top-0 stroke-black stroke-2 opacity-[0.5] ` +
                `h-[30px] w-[30px] md:h-[45px] md:w-[45px] m-[-8px] md:m-[-18px]`
              }
            />
            <QuoteIcon
              position="end"
              className={
                `absolute right-0 top-0 stroke-black stroke-2 opacity-[0.5] ` +
                `h-[30px] w-[30px] md:h-[45px] md:w-[45px] m-[-8px] md:m-[-18px]`
              }
            />
            <p className="text-xl md:text-2xl leading-9 font-light">
              Noto mi piel mas luminosa y jugosa. Los signos de expresión atenuados. Gracias Jorge.
            </p>
            <p className="mt-4 font-medium opacity-80">Nieves Álvarez</p>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-16 md:gap-20 mt-14">
          {quotes.map((quote, idx) => (
            <Quote key={idx} {...quote} />
          ))}
        </div>
      </Wrapper>
    </section>
  );
};
// -------------------------------------------------------------------------------------------------
// REVIEWS END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// CTA START
// -------------------------------------------------------------------------------------------------
const CTASection = ({ setShow }: IStateProps) => {
  return (
    <section id="secretos_CTA" className="bg-[rgba(29,74,255,0.1)] py-16 px-5">
      <Wrapper className="bg-white text-center">
        <Urgency id="secretos_CTA_PlacesLeft" className="mb-5 md:text-xl" />
        <p className="text-[38px] sm:text-[45px] font-semibold leading-[36px] text-[#1B4A93] mb-7">
          No te quedes sin tu plaza
        </p>
        <p className="text-[20px] leading-[24px]">
          Accede a los secretos que rejuvenecerán tu piel de 10 a 15 años
        </p>
        <div className="mt-10 flex justify-center">
          <PlayButton
            id="secretos_CTA_PlayButton"
            onClick={() => setShow(true)}
            className="w-full md:w-auto justify-center"
          >
            Quiero Verlo
          </PlayButton>
        </div>
      </Wrapper>
    </section>
  );
};
// -------------------------------------------------------------------------------------------------
// CTA END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// FOOTER START
// -------------------------------------------------------------------------------------------------
const Footer = () => (
  <section id="secretos_FOOTER" className="bg-[#2c334f]">
    <div className="max-w-7xl mx-auto px-5 py-6 text-[#9CA0AD] text-xs md:text-sm text-center">
      <p className="mb-4">Privacy Policy</p>
      <p>©2020 DR PLANAS COSMETICA. All rights reserved.</p>
      <p className="mb-4">
        This website is not a part of Facebook or Facebook Inc. Additionally, this site is NOT
        endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK Inc.
      </p>
    </div>
  </section>
);
// -------------------------------------------------------------------------------------------------
// FOOTER END
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// ICONS & SVGs
// -------------------------------------------------------------------------------------------------
const DrPlanasLogo = ({ className = "" }) => (
  <svg className={className} viewBox="0 0 528 768" fill="none">
    <path
      d="M7.5 263.5C59.8333 208.333 174.7 101.4 217.5 131C271 168 93 622.5 96.5 702C99.7355 775.491 200.39 475.971 317.097 374.188M317.097 374.188C326.639 365.866 336.288 358.866 346 353.5M317.097 374.188C314.567 438.767 320.755 488.587 330.5 498.5C347.123 515.409 362 340.5 346 353.5M317.097 374.188C322.095 246.592 361.127 61.3756 474 12.5C562 -25.6052 474 213 355.5 320C346 328.578 341.5 346.5 346 353.5M150 759.5C163 757 207.009 748.948 242 723.5C275 699.5 401 613 426.5 607M511 334.5L520.5 327"
      stroke="currentColor"
      strokeWidth="15"
      strokeLinecap="round"
    />
  </svg>
);

const QuoteIcon = ({ position, className }: { position: "start" | "end"; className?: string }) => (
  <svg
    className={`${className} h-5 w-5 m-1 fill-black/30 ${position === "end" && "rotate-180"}`}
    viewBox="0 0 144 144"
    aria-hidden="true"
  >
    <path d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
  </svg>
);

const StarIcon = ({ className = "" }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
    />
  </svg>
);

// The following SVGs are animated throught SMIL
// https://css-tricks.com/guide-svg-animations-smil/
const ClockAnimated = ({ className = "" }) => (
  <svg viewBox="0 0 100 100" fill="none" stroke="#000" strokeWidth="2px" className={className}>
    <circle id="big-circle" cx="50" cy="50" r="48" />
    <line id="minute-hand" x1="50" y1="50" x2="50" y2="10">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="1s"
        keyTimes="0; 1"
        repeatCount="indefinite"
      />
    </line>
    <line id="hour-hand" x1="50" y1="50" x2="25" y2="50">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="12s"
        keyTimes="0; 1"
        repeatCount="indefinite"
      />
    </line>
    <circle id="small-circle" cx="50" cy="50" r="4" fill="#fff" />
  </svg>
);
const HeartAnimated = ({ className = "" }) => (
  <svg
    viewBox="0 0 110 110"
    fill="none"
    stroke="#000"
    strokeWidth="2px"
    className={className + ` relative`}
    style={{ transformOrigin: "50% 50%", transform: "translate(0, 0)" }}
  >
    <path d="M48.1819 24.4658L48.182 24.4659L48.1821 24.466L48.9727 25.5905L49.799 24.492L49.7991 24.4919L49.7991 24.4918L49.7997 24.491L49.8034 24.4862L49.8196 24.4649C49.8343 24.4455 49.857 24.416 49.8872 24.3769C49.9476 24.2987 50.0383 24.1823 50.1572 24.0329C50.3951 23.734 50.7454 23.3031 51.1909 22.7807C52.0829 21.7347 53.3518 20.3275 54.86 18.8802C57.9232 15.9409 61.7981 13.0105 65.4225 12.3757C69.9542 11.5819 76.7167 12.4021 81.9639 15.7348C85.9753 18.2826 88.5888 21.3569 90.2121 24.9598C91.8428 28.5792 92.5 32.79 92.5 37.6407C92.5 42.2838 90.2513 47.1467 87.5986 51.2345C84.9613 55.2986 82.012 58.4644 80.7929 59.6835C70.6643 69.8121 54.2708 83.3102 49.6249 87.0957C49.2611 87.3922 48.7576 87.3889 48.3971 87.0871C43.8546 83.2839 28.0406 69.8642 18.7372 59.7149C18.5919 59.5565 18.4481 59.3996 18.3056 59.2443C14.5991 55.2024 11.8255 52.1779 9.93661 49.016C8.0088 45.789 7 42.4056 7 37.6407C7 32.7889 7.65223 28.5697 9.28036 24.9464C10.9009 21.3401 13.5134 18.2672 17.5328 15.7369C23.6572 11.8815 28.5473 11.5353 33.3269 12.3756C36.8219 12.99 40.5051 15.9039 43.4103 18.848C44.8388 20.2956 46.0352 21.7045 46.8748 22.7522C47.2941 23.2755 47.6232 23.7071 47.8464 24.0065C47.958 24.1562 48.0431 24.2727 48.0997 24.351C48.128 24.3901 48.1491 24.4197 48.1629 24.439L48.1781 24.4603L48.1814 24.465L48.1819 24.4658Z">
      <animateTransform
        attributeName="transform"
        type="scale"
        dur="2s"
        values="1.1; .9; 1.1"
        keyTimes="0; 0.5; 1"
        repeatCount="indefinite"
      />
      <animateTransform
        attributeName="transform"
        type="translate"
        dur="2s"
        values="0 0; 10 10; 0 0"
        keyTimes="0; 0.5; 1"
        repeatCount="indefinite"
        additive="sum"
      />
    </path>
  </svg>
);
const PlantAnimated = ({ className = "" }) => (
  <svg viewBox="0 0 100 100" fill="none" strokeWidth="2px" className={className}>
    <g style={{ strokeDasharray: "140", strokeDashoffset: "140" }}>
      <animate
        attributeName="stroke-dashoffset"
        dur="4000ms"
        values="140; -140"
        keyTimes="0; 1"
        fill="freeze"
        repeatCount="indefinite"
      />
      <path d="M81.5 81.5216C77.9783 84.1681 70.5 84.0216 70.5 84.0216C70.5 84.0216 63.0217 84.1681 59.5 81.5216C55.7995 78.7406 54 71.0216 54 71.0216C54 71.0216 59.8243 70.4657 63 72.0216C67.9627 74.4529 70.5 84.0216 70.5 84.0216V64.5216C70.5 64.5216 63.0217 64.6681 59.5 62.0216C55.7995 59.2406 54 51.5216 54 51.5216C54 51.5216 59.8573 51.3423 63 53.0216C67.729 55.5484 70.5 64.5216 70.5 64.5216V45.5216C70.5 45.5216 65.4239 46.2115 63 44.5216C60.3486 42.6731 59.5 37.0216 59.5 37.0216C59.5 37.0216 63.4041 36.9035 65.5 38.0216C68.6058 39.6784 70.5 45.5216 70.5 45.5216C70.5 45.5216 72.041 39.2781 74.5 38.0216C76.959 36.765 81.5 37.0216 81.5 37.0216C81.5 37.0216 80.3038 42.7447 77.5 44.5216C75.1675 45.9997 70.5 45.5216 70.5 45.5216V64.5216C70.5 64.5216 72.92 55.6033 77.5 53.0216C80.7719 51.1772 87 51.5216 87 51.5216C87 51.5216 85.2005 59.2406 81.5 62.0216C77.9783 64.6681 70.5 64.5216 70.5 64.5216V84.0216C70.5 84.0216 72.6885 74.5283 77.5 72.0216C80.8084 70.2979 87 71.0216 87 71.0216C87 71.0216 85.2005 78.7406 81.5 81.5216Z" />
      <path
        d="M70.5 96.0216V84.0216M70.5 84.0216C70.5 84.0216 77.9783 84.1681 81.5 81.5216C85.2005 78.7406 87 71.0216 87 71.0216C87 71.0216 80.8084 70.2979 77.5 72.0216C72.6885 74.5283 70.5 84.0216 70.5 84.0216ZM70.5 84.0216C70.5 84.0216 63.0217 84.1681 59.5 81.5216C55.7995 78.7406 54 71.0216 54 71.0216C54 71.0216 59.8243 70.4657 63 72.0216C67.9627 74.4529 70.5 84.0216 70.5 84.0216ZM70.5 84.0216V64.5216M70.5 64.5216C70.5 64.5216 77.9783 64.6681 81.5 62.0216C85.2005 59.2406 87 51.5216 87 51.5216C87 51.5216 80.7719 51.1772 77.5 53.0216C72.92 55.6033 70.5 64.5216 70.5 64.5216ZM70.5 64.5216C70.5 64.5216 63.0217 64.6681 59.5 62.0216C55.7995 59.2406 54 51.5216 54 51.5216C54 51.5216 59.8573 51.3423 63 53.0216C67.729 55.5484 70.5 64.5216 70.5 64.5216ZM70.5 64.5216V45.5216M70.5 45.5216C70.5 45.5216 75.1675 45.9997 77.5 44.5216C80.3038 42.7447 81.5 37.0216 81.5 37.0216C81.5 37.0216 76.959 36.765 74.5 38.0216C72.041 39.2781 70.5 45.5216 70.5 45.5216ZM70.5 45.5216C70.5 45.5216 65.4239 46.2115 63 44.5216C60.3486 42.6731 59.5 37.0216 59.5 37.0216C59.5 37.0216 63.4041 36.9035 65.5 38.0216C68.6058 39.6784 70.5 45.5216 70.5 45.5216Z"
        stroke="#000"
      />
      <path d="M41.5 81.5216C37.9783 84.1681 30.5 84.0216 30.5 84.0216C30.5 84.0216 23.0217 84.1681 19.5 81.5216C15.7995 78.7406 14 71.0216 14 71.0216C14 71.0216 19.8243 70.4657 23 72.0216C27.9627 74.4529 30.5 84.0216 30.5 84.0216V64.5216C30.5 64.5216 23.0217 64.6681 19.5 62.0216C15.7995 59.2406 14 51.5216 14 51.5216C14 51.5216 19.8573 51.3423 23 53.0216C27.729 55.5484 30.5 64.5216 30.5 64.5216V45.5216C30.5 45.5216 25.4239 46.2115 23 44.5216C20.3486 42.6731 19.5 37.0216 19.5 37.0216C19.5 37.0216 23.4041 36.9035 25.5 38.0216C28.6058 39.6784 30.5 45.5216 30.5 45.5216V33C30.5 33 28.1502 30.1779 28 28C27.8254 25.4676 30.5 22 30.5 22C30.5 22 33.1746 25.4676 33 28C32.8498 30.1779 30.5 33 30.5 33V45.5216C30.5 45.5216 32.041 39.2781 34.5 38.0216C36.959 36.765 41.5 37.0216 41.5 37.0216C41.5 37.0216 40.3038 42.7447 37.5 44.5216C35.1675 45.9997 30.5 45.5216 30.5 45.5216V64.5216C30.5 64.5216 32.92 55.6033 37.5 53.0216C40.7719 51.1772 47 51.5216 47 51.5216C47 51.5216 45.2005 59.2406 41.5 62.0216C37.9783 64.6681 30.5 64.5216 30.5 64.5216V84.0216C30.5 84.0216 32.6885 74.5283 37.5 72.0216C40.8084 70.2979 47 71.0216 47 71.0216C47 71.0216 45.2005 78.7406 41.5 81.5216Z" />
      <path
        d="M30.5 96.0216V84.0216M30.5 84.0216C30.5 84.0216 37.9783 84.1681 41.5 81.5216C45.2005 78.7406 47 71.0216 47 71.0216C47 71.0216 40.8084 70.2979 37.5 72.0216C32.6885 74.5283 30.5 84.0216 30.5 84.0216ZM30.5 84.0216C30.5 84.0216 23.0217 84.1681 19.5 81.5216C15.7995 78.7406 14 71.0216 14 71.0216C14 71.0216 19.8243 70.4657 23 72.0216C27.9627 74.4529 30.5 84.0216 30.5 84.0216ZM30.5 84.0216V64.5216M30.5 64.5216C30.5 64.5216 37.9783 64.6681 41.5 62.0216C45.2005 59.2406 47 51.5216 47 51.5216C47 51.5216 40.7719 51.1772 37.5 53.0216C32.92 55.6033 30.5 64.5216 30.5 64.5216ZM30.5 64.5216C30.5 64.5216 23.0217 64.6681 19.5 62.0216C15.7995 59.2406 14 51.5216 14 51.5216C14 51.5216 19.8573 51.3423 23 53.0216C27.729 55.5484 30.5 64.5216 30.5 64.5216ZM30.5 64.5216V45.5216M30.5 45.5216C30.5 45.5216 35.1675 45.9997 37.5 44.5216C40.3038 42.7447 41.5 37.0216 41.5 37.0216C41.5 37.0216 36.959 36.765 34.5 38.0216C32.041 39.2781 30.5 45.5216 30.5 45.5216ZM30.5 45.5216C30.5 45.5216 25.4239 46.2115 23 44.5216C20.3486 42.6731 19.5 37.0216 19.5 37.0216C19.5 37.0216 23.4041 36.9035 25.5 38.0216C28.6058 39.6784 30.5 45.5216 30.5 45.5216ZM30.5 45.5216V33M30.5 33C30.5 33 28.1502 30.1779 28 28C27.8254 25.4676 30.5 22 30.5 22C30.5 22 33.1746 25.4676 33 28C32.8498 30.1779 30.5 33 30.5 33Z"
        stroke="#000"
      />
    </g>
    <path d="M9 96H91.5" stroke="#000" />
  </svg>
);
