import React from "react";
import RelativeTime from "../../utils/relativeTime";

interface IProofPopupProps {
  id: string;
  duration?: number;
  delay?: number;
  proofsList?: IProofProps[];
}
export default ({ id, duration = 5000, delay = 2500, proofsList = proofs }: IProofPopupProps) => {
  const [show, setShow] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [time, setTime] = React.useState("");

  React.useEffect(() => {
    setTime(RelativeTime(proofsList[index].timestamp)); // convert timestamp to relative time
    setTimeout(() => {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setTimeout(() => setIndex((index + 1) % proofsList.length), 1000); // index changed when hidden
      }, duration);
    }, delay);
  }, [index]);

  return (
    <div
      id={id}
      className={
        `${
          show
            ? "translate-y-0 md:translate-x-0 opacity-100"
            : "-translate-y-[88px] md:translate-y-0 md:-translate-x-[362px] opacity-0"
        }  ` +
        `top-0 bottom-auto md:top-auto md:bottom-0 ` +
        `w-full md:w-[352px] md:mb-[10px] md:ml-[10px] ` +
        `md:rounded-full ` +
        `fixed  left-0 p-4 h-[88px] grid grid-cols-[56px_1fr] gap-4 overflow-hidden leading-[1rem] border border-gray-300/50 shadow-[10px_20px_40px_0_rgba(36,35,40,0.1)] bg-white z-[100]`
      }
      style={{ transition: "all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35)" }}
    >
      <div className="w-[54px] h-[54px] rounded-full bg-[#0045F8] flex justify-center items-center">
        <EyeIcon className="w-6 h-6 stroke-white" />
      </div>
      <div>
        <div>
          <span className="text-sm leading-[14px] text-gray-900 font-medium align-middle">
            {proofsList[index].person}
          </span>
        </div>
        <div className="mt-[9px] mb-0.5 leading-[13px] text-[13px] text-gray-700 font-light">
          {proofsList[index].action}
        </div>
        <div className="inline-block mr-1 font-light text-gray-500 leading-[11px] text-[11px]">
          {time}
        </div>
      </div>
    </div>
  );
};

interface IProofProps {
  person: string;
  action: string;
  timestamp: number;
}
const proofs: IProofProps[] = [
  {
    person: "Manuela, desde Córdoba",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 59 * 1000, // 59 seconds ago
  },
  {
    person: "Olga, desde Alicante",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 61 * 1000, // 1 minute ago
  },
  {
    person: "María, desde Córdoba",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 59 * 60 * 1000, // 59 minutes ago
  },
  {
    person: "Mercedes, desde Málaga",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 60 * 60 * 1000, // 1 hour ago
  },
  {
    person: "Pilar, desde Madrid",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 23 * 60 * 60 * 1000, // 23 hours ago
  },
  {
    person: "Cristina, desde Tarragona",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: +new Date() - 25 * 60 * 60 * 1000, // 1 day ago
  },
  {
    person: "Paloma, desde Asturias",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: 1652445745628,
  },
  {
    person: "Alicia, desde Santander",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: 1652445745628,
  },
  {
    person: "Begoña, desde Vizcaya",
    action: "Atendió a la Masterclass del Dr. Planas",
    timestamp: 1652445745628,
  },
];

const EyeIcon = ({ className }: { className: string }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);
